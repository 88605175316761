import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Container} from '@material-ui/core';

import Header from '../header';
import {messages as M} from '../../messages';
import './style.scss';

import SEO from "../../../components/seo"

const Section = ({subTitle, content}) => {
    return (
        <Box className="section">
            <Typography variant="subtitle1" className="sub-title" color="secondary" gutterBottom>
                {subTitle}
            </Typography>
            {content}
        </Box>
    );
};

Section.propTypes = {
    subTitle: PropTypes.any,
    content: PropTypes.any
};

const Content = ({content}) => {
    return (
        <Typography variant="body1" color="secondary" className="content" gutterBottom>
            {content}
        </Typography>
    );
};

Content.propTypes = {
    content: PropTypes.node
};

const LisContent = ({items}) => {
    return items.map((item, index) => {
        return <Typography key={index} variant="body1" color="secondary" className="content list-item" gutterBottom>
            {item}
        </Typography>;
    });
};

LisContent.propTypes = {
    items: PropTypes.array
};

function PrivacyPolicy() {
    return (
        <Container className="container privacy-policy">
            <SEO title={M.get('siteMetaData.policy.title')} description={M.get('siteMetaData.policy.description')} />
            <Header />
            <Typography variant="subtitle1" className="privacy-policy-title" color="primary">
                {M.get('privacyPolicy.title')}
            </Typography>
            <Typography variant="body1" color="secondary" className="content" gutterBottom> {M.get('privacyPolicy.description')} </Typography>
            <Section
                subTitle={M.get('privacyPolicy.personalInformation.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.personalInformation.description1')} />
                        <LisContent
                            items={[
                                M.get('privacyPolicy.personalInformation.firstAndLastName'),
                                M.get('privacyPolicy.personalInformation.mailingAddress'),
                                M.get('privacyPolicy.personalInformation.emailAddress'),
                                M.get('privacyPolicy.personalInformation.phoneNumber'),
                                M.get('privacyPolicy.personalInformation.employer'),
                                M.get('privacyPolicy.personalInformation.jobTitle'),
                                M.get('privacyPolicy.personalInformation.symptoms')
                            ]}
                        />
                        <Content content={M.get('privacyPolicy.personalInformation.description2')} />
                        <Content content={M.get('privacyPolicy.personalInformation.description3')} />
                        <LisContent
                            items={[
                                M.get('privacyPolicy.personalInformation.age'),
                                M.get('privacyPolicy.personalInformation.gender')
                            ]}
                        />
                        <Content content={M.get('privacyPolicy.personalInformation.description4')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.usePersonalInformation.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.usePersonalInformation.description1')} />
                        <Content content={M.get('privacyPolicy.usePersonalInformation.description2')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.sharingInformation.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.sharingInformation.description1')} />
                        <Content content={M.get('privacyPolicy.sharingInformation.description2')} />
                        <Content content={M.get('privacyPolicy.sharingInformation.description3')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.trackingUserBehavior.title')}
                content={<Content content={M.get('privacyPolicy.trackingUserBehavior.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.automaticallyCollectedInformation.title')}
                content={<Content content={M.get('privacyPolicy.automaticallyCollectedInformation.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.useOfCookies.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.useOfCookies.description1')} />
                        <Content content={M.get('privacyPolicy.useOfCookies.description2')} />
                        <Content content={M.get('privacyPolicy.useOfCookies.description3')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.links.title')}
                content={<Content content={M.get('privacyPolicy.links.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.security.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.security.description1')} />
                        <LisContent items={[M.get('privacyPolicy.security.sslProtocol')]} />
                        <Content content={M.get('privacyPolicy.security.description2')} />
                        <Content content={M.get('privacyPolicy.security.description3')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.childrenUnderThirteen.title')}
                content={<Content content={M.get('privacyPolicy.childrenUnderThirteen.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.emailCommunications.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.emailCommunications.description1')} />
                        <Content content={M.get('privacyPolicy.emailCommunications.description2')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('privacyPolicy.externalDataStorageSites.title')}
                content={<Content content={M.get('privacyPolicy.externalDataStorageSites.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.changesToThisStatement.title')}
                content={<Content content={M.get('privacyPolicy.changesToThisStatement.description1')} />}
            />
            <Section
                subTitle={M.get('privacyPolicy.contactInformation.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('privacyPolicy.contactInformation.description1')} />

                        <Box className="contact-container">
                            <Box className="contact">
                                <Typography variant="body2" color="secondary" gutterBottom> {M.get('privacyPolicy.contactInformation.netGenixIncL')} </Typography>
                                <Typography variant="body1" color="secondary"> {M.get('privacyPolicy.contactInformation.suiteJ355')} </Typography>
                                <Typography variant="body1" color="secondary"> {M.get('privacyPolicy.contactInformation.burbank')} </Typography>
                            </Box>
                            <Box className="contact">
                                <Typography variant="body2" color="secondary" className="contact-subtitle" gutterBottom> {M.get('privacyPolicy.contactInformation.emailAddress')} </Typography>
                                <Typography variant="body1" color="secondary" gutterBottom> {'contact@netgenix.com'} </Typography>
                            </Box>
                            <Box className="contact">
                                <Typography variant="body2" color="secondary"className="contact-subtitle" gutterBottom> {M.get('privacyPolicy.contactInformation.telephoneNumber')} </Typography>
                                <Typography variant="body1" color="secondary" gutterBottom> {'8182529704'} </Typography>
                            </Box>
                        </Box>

                    </React.Fragment>
                }
            />
            <Typography variant="body1" color="secondary" className="content date" gutterBottom>
                {M.get('privacyPolicy.date')}
            </Typography>
        </Container>
    );
}

export default PrivacyPolicy;